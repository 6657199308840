
import React from 'react'
import {ref} from '../../config/constants'
import { v4 as uuidv4 } from 'uuid';

export default class MenuCardItemAddButton extends React.Component {
    constructor(props){
        super(props)
        this.state = {
        }
        // this.componentDidMount = this.componentDidMount.bind(this);
    }


    componentDidMount () {
    }

    componentWillUnmount () {
    }

    render() {
        return (
            <button type="button" className="btn btn-success" data-toggle="modal" data-target={"#addMenuCardItemModal" + this.props.card}>
                Añadir Elemento
            </button>
        )
    }
}