
import React from 'react'
import {ref} from '../../config/constants'
import { v4 as uuidv4 } from 'uuid';

export default class MenuCardItemAddModal extends React.Component {
    constructor(props){
        super(props)
        this.state = {
        }
        // this.componentDidMount = this.componentDidMount.bind(this);
        this.addMenuCardItem = this.addMenuCardItem.bind(this);
    }


    componentDidMount () {
    }

    componentWillUnmount () {
    }

    addMenuCardItem(){
        var id = uuidv4()
        ref.child(`menu/${this.props.card}/itemCount`).once('value').then(result => {
            ref.child(`menuItem/${id}`).set({
                es: {
                    itemTitle: this.esTitle.value,
                    itemText: this.esText.value
                },
                eus: {
                    itemTitle: this.eusTitle.value,
                    itemText: this.eusText.value
                },
                en: {
                    itemTitle: this.enTitle.value,
                    itemText: this.enText.value
                },
                price: this.price.value,
                menu: this.props.card,
                pos: result.val() + 1
            })
            ref.child(`menu/${this.props.card}/itemCount`).set(result.val() + 1)
            window.location.reload()
        })

        ref.child(`menu/${this.props.card}/menuItems`).push(id)
        
    }

    render() {
        return (
            <div id={"addMenuCardItemModal" + this.props.card} className="modal fade" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Añadir Elemento </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <h3>Castellano</h3>
                                        <hr/>
                                        <label>Titulo</label>
                                        <input type="text" className="form-control" ref={(esTitle) => this.esTitle = esTitle}/>
                                        <label>Descripción</label>
                                        <input type="text" className="form-control" ref={(esText) => this.esText = esText}/>
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <h3>Euskera</h3>
                                        <hr/>
                                        <label>Titulo</label>
                                        <input type="text" className="form-control" ref={(eusTitle) => this.eusTitle = eusTitle}/>
                                        <label>Descripción</label>
                                        <input type="text" className="form-control" ref={(eusText) => this.eusText = eusText}/>
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <h3>Ingles</h3>
                                        <hr/>
                                        <label>Titulo</label>
                                        <input type="text" className="form-control" ref={(enTitle) => this.enTitle = enTitle}/>
                                        <label>Descripción</label>
                                        <input type="text" className="form-control" ref={(enText) => this.enText = enText}/>
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <h3>Precio</h3>
                                        <input type="text" className="form-control" ref={(price) => this.price = price}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                            <button type="button" className="btn btn-primary" onClick={this.addMenuCardItem}>Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}