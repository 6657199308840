
import React from 'react'
import CarouselImage from './carouselImage'
import '../../styles/site.css'
import {storage} from '../../config/constants'

export default class Carousel extends React.Component {
    constructor(){
        super()
        this.state = {
            imageCount: 0,
            imageDivCollection: []
        }
    }

    componentDidMount () {
        storage.child('inicio').listAll().then(result => 
            {
                this.setState({imageCount: result.items.length})
                var imageCollection = [];
                var cont = 0;
                for(var item in result.items){
                    var img = result.items[item].name;
                    storage.child(`inicio/${img}`).getDownloadURL().then( url => {
                        if(cont == 0){
                            imageCollection.push(
                                <div key={cont} className="carousel-item active">
                                    <img src={url} className="d-block w-100" alt="..."/>
                                </div>
                            );
                        } else{
                            imageCollection.push(
                                <div key={cont} className="carousel-item">
                                    <img src={url} className="d-block w-100" alt="..."/>
                                </div>
                            );
                        }
                        cont++;
                        if(result.items.length = cont){
                            this.setState({
                                imageDivCollection: imageCollection
                            })
                        }
                    })
                }
            });
    }

    componentWillUnmount () {
    }

    render() {
            return (
                    <div>
                        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                            {/* <div className="carousel-item active">
                                <img src="..." className="d-block w-100" alt="..."/>
                            </div>
                            <div className="carousel-item">
                                <img src="..." className="d-block w-100" alt="..."/>
                            </div>
                            <div className="carousel-item">
                                <img src="..." className="d-block w-100" alt="..."/>
                            </div> */}
                            {this.state.imageDivCollection}
                        </div>
                        <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                        </div>
                    </div>
            )
    }
}
