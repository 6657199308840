
import React from 'react'
import {ref} from '../../config/constants'
import { locStrings } from '../../language/LangResource'
import MenuCardItem from './menuCardItem'
import MenuCardItemAddButton from '../common/menuCardItemAddButton'
import MenuCardItemAddModal from '../common/menuCardItemAddModal'
import MenuCardDeleteModal from '../common/menuCardDeleteModal'
import MenuCardDeleteButton from '../common/menuCardDeleteButton'
import MenuCardEditButton from '../common/menuCardEditButton'
import MenuCardEditModal from '../common/menuCardEditModal'

export default class MenuCard extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            menuTitle: '',
            menuItemCollection: []
        }
        // this.componentDidMount = this.componentDidMount.bind(this);
    }


    componentDidMount () {
        ref.child(`menu/${this.props.card}`).once('value').then(result => {
            this.setState({
                menuTitle:  
                    <label key={result.val()[locStrings.getLanguage()].itemTitle}>
                        {result.val()[locStrings.getLanguage()].itemTitle}
                    </label>
            });
        });
        var itemCollection = [];
        ref.child(`menu/${this.props.card}/menuItems`).once('value').then(result => {
            for(var item in result.val()){
                itemCollection.push(
                    <MenuCardItem 
                        key={item} card={this.props.card} item={result.val()[item]} authed={this.props.authed}>
                    </MenuCardItem>
                )
            }
            this.setState({
                menuItemCollection: itemCollection
            });
        });
    }

    componentWillUnmount () {
    }


    render() {
        return (
            <div className="col-md-6">
                <div className="card menu-card-div">
                    <div className="card-header menu-title-div">
                    {this.state.menuTitle}
                    {this.props.authed &&
                        <div>
                            <MenuCardEditButton card={this.props.card} type={this.props.type}></MenuCardEditButton>
                            <MenuCardEditModal card={this.props.card} type={this.props.type}></MenuCardEditModal>
                            <MenuCardDeleteButton card={this.props.card} type={this.props.type}></MenuCardDeleteButton>
                            <MenuCardDeleteModal card={this.props.card} type={this.props.type}></MenuCardDeleteModal>
                        </div>
                    }
                    </div>
                    {this.state.menuItemCollection}
                    {this.props.authed &&
                        <div>
                            <div className="text-center">
                                <br/>
                                <MenuCardItemAddButton card={this.props.card}></MenuCardItemAddButton>
                            </div>
                            <br/>
                            <MenuCardItemAddModal card={this.props.card}></MenuCardItemAddModal>
                        </div>
                    }
                </div>

            </div>
        )
    }
}
