
import React from 'react'
import {ref} from '../../config/constants'
import { v4 as uuidv4 } from 'uuid';

export default class MenuCardAddModal extends React.Component {
    constructor(props){
        super(props)
        this.state = {
        }
        // this.componentDidMount = this.componentDidMount.bind(this);
        this.addMenuCard = this.addMenuCard.bind(this);
    }


    componentDidMount () {
    }

    componentWillUnmount () {
    }

    addMenuCard(){
        var id = uuidv4()
        ref.child(`menu/${this.props.type}Count`).once('value').then(result => {
            ref.child(`menu/${id}`).set({
                es: {
                    itemTitle: this.esTitle.value
                },
                eus: {
                    itemTitle: this.eusTitle.value
                },
                en: {
                    itemTitle: this.enTitle.value
                },
                type: this.props.type,
                pos: result.val() + 1,
                itemCount: 0
            })
            ref.child(`menu/${this.props.type}Count`).set(result.val() + 1)
            $('#addMenuCardModal').modal('hide')
            window.location.reload()
        })
    }

    render() {
        return (
            <div className="row menu-div">
                <div className="col-md-12 text-center">
                    <br/>
                    <button type="button" className="btn btn-success" data-toggle="modal" data-target="#addMenuCardModal">
                        Añadir Sección
                    </button>
                </div>
                <div id="addMenuCardModal" className="modal fade" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Añadir Sección</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12 form-group">
                                            <label>Titulo en Castellano</label>
                                            <input type="text" className="form-control" ref={(esTitle) => this.esTitle = esTitle}/>
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <label>Titulo en Euskera</label>
                                            <input type="text" className="form-control" ref={(eusTitle) => this.eusTitle = eusTitle}/>
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <label>Titulo en Ingles</label>
                                            <input type="text" className="form-control" ref={(enTitle) => this.enTitle = enTitle}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                <button type="button" className="btn btn-primary" onClick={this.addMenuCard}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
