
import React from 'react'
import {ref} from '../../config/constants'

export default class MenuCardItemEditModal extends React.Component {
    constructor(props){
        super(props)
        this.state = {
        }
        // this.componentDidMount = this.componentDidMount.bind(this);
        this.editCardItem = this.editCardItem.bind(this);
    }


    componentDidMount () {
        ref.child(`menuItem/${this.props.item}`).once('value').then(result =>{
            this.esTitle.value = result.val()['es'].itemTitle,
            this.esText.value = result.val()['es'].itemText,
            this.eusTitle.value = result.val()['eus'].itemTitle,
            this.eusText.value = result.val()['eus'].itemText,
            this.enTitle.value = result.val()['en'].itemTitle
            this.enText.value = result.val()['en'].itemText
            this.price.value = result.val().price
        })
    }

    componentWillUnmount () {
    }

    editCardItem(){
        ref.child(`menuItem/${this.props.item}`).once('value').then( result => {
            ref.child(`menuItem/${this.props.item}`).set({
                es: {
                    itemTitle: this.esTitle.value,
                    itemText: this.esText.value
                },
                eus: {
                    itemTitle: this.eusTitle.value,
                    itemText: this.eusText.value
                },
                en: {
                    itemTitle: this.enTitle.value,
                    itemText: this.enText.value
                },
                price: this.price.value,
                menu: result.val().menu,
                pos: result.val().pos,
            })
            $("editCardItemModal" + this.props.card).modal('hide')
            window.location.reload()
        })
    }

    render() {
        return (
            <div id={"editCardItemModal" + this.props.item} className="modal fade"  tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Modificar Plato</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 form-group">
                                    <h3>Castellano</h3>
                                    <hr/>
                                    <label>Titulo</label>
                                    <input type="text" className="form-control" ref={(esTitle) => this.esTitle = esTitle}/>
                                    <label>Descripción</label>
                                    <input type="text" className="form-control" ref={(esText) => this.esText = esText}/>
                                </div>
                                <div className="col-md-12 form-group">
                                    <h3>Euskera</h3>
                                    <hr/>
                                    <label>Titulo</label>
                                    <input type="text" className="form-control" ref={(eusTitle) => this.eusTitle = eusTitle}/>
                                    <label>Descripción</label>
                                    <input type="text" className="form-control" ref={(eusText) => this.eusText = eusText}/>
                                </div>
                                <div className="col-md-12 form-group">
                                    <h3>Ingles</h3>
                                    <hr/>
                                    <label>Titulo</label>
                                    <input type="text" className="form-control" ref={(enTitle) => this.enTitle = enTitle}/>
                                    <label>Descripción</label>
                                    <input type="text" className="form-control" ref={(enText) => this.enText = enText}/>
                                </div>
                                <div className="col-md-12 form-group">
                                    <h3>Precio</h3>
                                    <input type="text" className="form-control" ref={(price) => this.price = price}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={this.editCardItem}>Guardar</button>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}
