
import React from 'react'
import {ref} from '../../config/constants'
import VinoCard from './vinoCard'
import MenuCardAddModal from '../common/menuCardAddModal'

export default class Vino extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            loaded: false,
            menuCardList: ''
        }
        // this.componentDidMount = this.componentDidMount.bind(this);
    }


    componentDidMount () {
        //TODO: Refactorizar para no recorrer toda la lista
        ref.child(`menu`).orderByChild('pos').on('value', cardCollection => {
            var menuCardCollection = []
            var authed = this.props.authed
            cardCollection.forEach(function(card){
                if(card.val().type == 'vino'){
                    menuCardCollection.push(
                        <VinoCard key={card.key} authed={authed} card={card.key} pos={card.val().pos} type="vino"></VinoCard>
                    )
                }
            })
            this.setState({menuCardList: menuCardCollection})
        })
    }

    componentWillUnmount () {
    }

    render() {
        return (
            <div className="container-fluid">
                {this.props.authed &&
                    <MenuCardAddModal type="vino"></MenuCardAddModal>
                }
                 <div className="row menu-div">
                    {this.state.menuCardList}
                </div>
            </div>
        )
    }
}
