import React from 'react'

export default class CarouselImage extends React.Component {
    constructor(){
        super()
        this.state = {
        }
    }

    componentDidMount () {
    }

    componentWillUnmount () {
    }

    render() {
        return (
            <div key={this.props.item} className="carousel-item">
                <img className="d-block w-100" alt='...'
                    src={this.props.itemUrl}>
                </img>
            </div>
        )
        
    }
}