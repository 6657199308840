import React from 'react'
import GoogleMapsDiv from './googleMapsDiv'

export default class Contacto extends React.Component {
    constructor(){
        super()
    }

    componentDidMount () {
    }

    componentWillUnmount () {
    }

    render() {
        return (
            <div className="container-fluid">
                <GoogleMapsDiv></GoogleMapsDiv>
            </div>
        )
    }
}