
import React from 'react'
import {ref} from '../../config/constants'

export default class MenuCardDeleteModal extends React.Component {
    constructor(props){
        super(props)
        this.state = {
        }
        // this.componentDidMount = this.componentDidMount.bind(this);
        this.deleteCard = this.deleteCard.bind(this);
    }


    componentDidMount () {
    }

    componentWillUnmount () {
    }

    deleteCard(){
        ref.child(`menu/${this.props.card}/menuItems`).on('value', itemCollection =>{
            itemCollection.forEach(item =>{
                ref.child(`menuItem/${item.val()}`).remove()
            })
        })
        ref.child(`menu/${this.props.card}`).remove()
        ref.child(`menu/${this.props.type}Count`).once('value').then(result => {
            ref.child(`menu/${this.props.type}Count`).set(result.val() - 1)
            //TODO: Falta Reordenar pos
            $('#deleteCardModal' + this.props.card).modal('hide')
            window.location.reload()
        })
    }

    render() {
        return (
            <div id={"deleteCardModal" + this.props.card} className="modal fade"  tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Eliminar Sección</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 form-group">
                                    <label>¿ Estas Seguro ?</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-danger" onClick={this.deleteCard}>Eliminar</button>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}
