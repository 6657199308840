import React from 'react'
import Carousel from './carousel'

export default class Inicio extends React.Component {
    constructor(){
        super()
    }

    componentDidMount () {
    }

    componentWillUnmount () {
    }

    render() {
        return (
            <div className="container-fluid">
                <Carousel></Carousel>
            </div>
        )
    }
}
