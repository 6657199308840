import React from 'react'
import {storage} from '../../config/constants'
import { locStrings } from '../../language/LangResource'
import { login } from '../../helpers/authFunctions'

export default class LoginView extends React.Component {
    constructor(props){
        super(props)
        this.state = {
        }
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(e) {
        e.preventDefault();
        login(this.email.value, this.password.value)
        .then( (result) => { 
        })
        .catch( (error) => {
            alert(error.message)
        });
    }

    render() {
            return (
                <div className="container-fluid">
                    <div className="row menu-div">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <label >Email</label>
                                    <input id="email" key="email" type="email" className="form-control" 
                                    aria-describedby="emailHelp" ref={(email) => this.email = email}/>
                                </div>
                                <div className="form-group">
                                    <label >Password</label>
                                    <input id="password" key="password" type="password" className="form-control" 
                                    aria-describedby="passwordlHelp" ref={(password) => this.password = password}/>
                                </div>
                                <div className="form-group text-center">
                                    <button type="submit" className="btn btn-primary">Login</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-4"></div>
                    </div>
                </div>
            )
    }
}
