import React from 'react'
import { Map } from 'google-maps-react';
import { GoogleApiWrapper } from './googleApiWrapper';

const mapStyles = {
  width: '100%',
  height: '100%'
};

export default class GoogleMapsDiv extends React.Component {
    constructor(){
        super()
    }

    componentDidMount () {
        let map;

        function initMap() {
        map = new google.maps.Map(document.getElementById("map"), {
            center: { lat: -34.397, lng: 150.644 },
            zoom: 8,
        });
}
    }

    componentWillUnmount () {
    }

    render() {
        return (
            <div className="row">
                <Map
                        google={this.props.google}
                        zoom={14}
                        style={mapStyles}
                        initialCenter={
                        {
                            lat: -1.2884,
                            lng: 36.8233
                        }
                        }
                    />
            </div>
        )
    }
}

