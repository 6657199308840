
import React, { Component } from 'react'
import { Route, BrowserRouter, Redirect, Switch } from 'react-router-dom'
import Header from '../components/header'
import NavBar from '../components/navBar'
import Inicio from '../components/inicio/inicio'
import Menu from '../components/menu/menu'
import Vino from '../components/vino/vino'
import Contacto from '../components/contacto/contacto'
import imaiFont from '../fonts/imaiFont.otf'
import { injectGlobal } from 'styled-components'
import '../styles/site.css'
import { locStrings } from '../language/LangResource'
import cookie  from 'react-cookies';
import LoginView from '../components/login/loginView'
import { firebaseAuth } from '../config/constants'
// injectGlobal
//     @font-face {
//         font-family: 'iamai-font';
//         src: url(${imaiFont}) format('opentype');
//         font-weight: normal;
//         font-style: normal;
//     }
// ;

function LoginRoute({component: Component, authed, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => !authed 
                ? <Component {...props} />
                : <Redirect to={{pathname: '/menu', state: {from: props.location}}}/>}
        />
    )
}

function EditableRoute({component: Component, authed, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => <Component {...props} authed={authed}/> }
        />
    )
}

export default class App extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            lang: '',
            authed: false
        }
    }

    componentDidMount () {
        if(cookie.load("appCulture") != undefined){
            locStrings.setLanguage(cookie.load("appCulture"));
        } else{
            cookie.save("appCulture", "eus");
            locStrings.setLanguage("eus");
        }
        this.removeListener = firebaseAuth.onAuthStateChanged((user) => {
            if (user) {
                this.setState({
                    authed: true
                })
            } else {
                this.setState({
                    authed: false
                })
            }
        })
    }

    componentWillUnmount () {
        this.removeListener()
    }

    render() {
        return (
            <BrowserRouter>
                    <div>
                    <header>
                        <Header authed={this.state.authed}></Header>
                        <NavBar></NavBar>
                    </header>
                        <main>
                            <Switch>
                                <Route path='/' exact component={Inicio}/>
                                <Route path='/inicio' exact component={Inicio}/>
                                <EditableRoute authed={this.state.authed} path='/menu' component={Menu} />
                                <EditableRoute authed={this.state.authed} path='/vino' exact component={Vino}/>
                                <Route path='/contacto' exact component={Contacto}/>
                                <LoginRoute authed={this.state.authed} path='/login' exact component={LoginView}/>
                            </Switch>
                        </main>
                        <footer className="footer">
                            <div className="container">
                                <div className="text-center">
                                    <p>
                                        <label>Kontzezino Kalea, 14, 20500 Arrasate, Gipuzkoa</label>
                                    </p>
                                </div>
                            </div>
                        </footer>
                    </div>
            </BrowserRouter>
        );
    }
}
