
import React from 'react'
import {ref} from '../../config/constants'
import { v4 as uuidv4 } from 'uuid';
import { locStrings } from '../../language/LangResource';

export default class MenuCardEditModal extends React.Component {
    constructor(props){
        super(props)
        this.state = {
        }
        // this.componentDidMount = this.componentDidMount.bind(this);
        this.editMenuCard = this.editMenuCard.bind(this);
    }


    componentDidMount () {
        ref.child(`menu/${this.props.card}`).once('value').then(result =>{
            this.esTitle.value = result.val()['es'].itemTitle,
            this.eusTitle.value = result.val()['eus'].itemTitle,
            this.enTitle.value = result.val()['en'].itemTitle
        })
    }

    componentWillUnmount () {
    }

    editMenuCard(){
        ref.child(`menu/${this.props.card}`).once('value').then( result => {
            ref.child(`menu/${this.props.card}`).set({
                es: {
                    itemTitle: this.esTitle.value
                },
                eus: {
                    itemTitle: this.eusTitle.value
                },
                en: {
                    itemTitle: this.enTitle.value
                },
                menuItems: result.val().menuItems,
                type: result.val().type,
                pos: result.val().pos,
                itemCount: result.val().itemCount
            })
            $("editCardModal" + this.props.card).modal('hide')
            window.location.reload()
        })
    }

    render() {
        return (
                <div id={"editCardModal" + this.props.card} className="modal fade" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Añadir Sección</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12 form-group">
                                            <label>Titulo en Castellano</label>
                                            <input type="text" className="form-control" ref={(esTitle) => this.esTitle = esTitle}/>
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <label>Titulo en Euskera</label>
                                            <input type="text" className="form-control" ref={(eusTitle) => this.eusTitle = eusTitle}/>
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <label>Titulo en Ingles</label>
                                            <input type="text" className="form-control" ref={(enTitle) => this.enTitle = enTitle}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                <button type="button" className="btn btn-primary" onClick={this.editMenuCard}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}
