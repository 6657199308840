import firebase from 'firebase'

const config = {
    apiKey: "AIzaSyCZH58Vvo95ukJdH4WwEOKkD-WB0Sa-JyY",
    authDomain: "iamai-web.firebaseapp.com",
    databaseURL: "https://iamai-web-default-rtdb.firebaseio.com",
    projectId: "iamai-web",
    storageBucket: "iamai-web.appspot.com",
    messagingSenderId: "320509005134",
    appId: "1:320509005134:web:f86ce19b179be3c9e2621f"
  };

var firebaseApp = firebase.initializeApp(config)

var firebaseAdmin = firebase.initializeApp(config,'admin')

export const ref = firebaseApp.database().ref()
export const storage = firebaseApp.storage().ref()
export const firebaseAuth = firebaseApp.auth()
export const firebaseAdminAuth = firebaseAdmin.auth()

export const auth = firebaseApp.auth();
