
import React from 'react'
import {ref} from '../../config/constants'

export default class MenuCardItemDeleteModal extends React.Component {
    constructor(props){
        super(props)
        this.state = {
        }
        // this.componentDidMount = this.componentDidMount.bind(this);
        this.deleteCardItem = this.deleteCardItem.bind(this);
    }


    componentDidMount () {
    }

    componentWillUnmount () {
    }

    deleteCardItem(){
        ref.child(`menuItem/${this.props.item}`).remove()
        //TODO: Refactorizar para no recorrer toda la lista
        ref.child(`menu/${this.props.card}/menuItems`).on('value', itemCollection => {
            itemCollection.forEach(menuItem => {
                if(menuItem.val() == this.props.item) {
                    ref.child(`menu/${this.props.card}/menuItems/${menuItem.key}`).remove()
                }
            });
        })
        ref.child(`menu/${this.props.card}/itemCount`).once('value').then(result => {
            ref.child(`menu/${this.props.card}/itemCount`).set(result.val() - 1)
            //TODO: Falta Reordenar pos
            $('#deleteCardItemModal' + this.props.item).modal('hide')
            window.location.reload()
        })

    }

    render() {
        return (
            <div id={"deleteCardItemModal" + this.props.item} className="modal fade"  tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Eliminar Plato</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 form-group">
                                    <label>¿ Estas Seguro ?</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-danger" onClick={this.deleteCardItem}>Eliminar</button>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}
