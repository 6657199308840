
import React from 'react'
import '../styles/site.css'
import {storage} from '../config/constants'
import { locStrings } from '../language/LangResource'
import cookie  from 'react-cookies';
import LoginView from './login/loginView'
import { logout } from '../helpers/authFunctions'

export default class Header extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            esUrl: '',
            enUrl: '',
            eusUrl: '',
            langUrl: ''
        }
    }

    componentDidMount () {
        storage.child('images/es.png').getDownloadURL().then( (url) => {
            this.setState(
                {esUrl: url}
            )
          });
        storage.child('images/en.png').getDownloadURL().then( (url) => {
            this.setState(
                {enUrl: url}
            )
          });
        storage.child('images/eus.png').getDownloadURL().then( (url) => {
            this.setState(
                {eusUrl: url}
            )
        });
        if(cookie.load("appCulture") != undefined){
            storage.child('images/' + cookie.load("appCulture") +'.png').getDownloadURL().then( (url) => {
                this.setState(
                    {langUrl: url}
                )
            });
        } else{
            storage.child('images/' + "es" +'.png').getDownloadURL().then( (url) => {
                this.setState(
                    {langUrl: url}
                )
            });
        }
        
    }

    componentWillUnmount () {
    }

    logOutClick(){
        logout();
        window.location.reload();
    }
    
    handleLangChange(){
        locStrings.setLanguage(this);
        cookie.save("appCulture", this);
        storage.child('images/' + locStrings.getLanguage() +'.png').getDownloadURL().then( (url) => {
            this.setState(
                {langUrl: url}
            )
        });
        window.location.reload();
    }

    render() {
        return (
            <div className="row navbar-color">
                <div className="col-sm-4 navbar-lang-div">
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {locStrings[locStrings.getLanguage()]}  
                            {/* <img src={this.state.langUrl}/>  */}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a className="dropdown-item" value="en" onClick={this.handleLangChange.bind('en')}>
                                {locStrings.en}  
                                {/* <img src={this.state.enUrl}/>  */}
                            </a>
                            <a className="dropdown-item" value="es" onClick={this.handleLangChange.bind('es')}>
                                {locStrings.es}  
                                {/* <img src={this.state.esUrl}/>  */}
                            </a>
                            <a className="dropdown-item" value="eus" onClick={this.handleLangChange.bind('eus')}>
                                {locStrings.eus}  
                                {/* <img src={this.state.eusUrl}/>  */}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 navbar-principal-div text-center">
                    <div className="navbar-text-div">
                        <label className="navbar-text-label1">IAMAI</label>
                        <label className="navbar-text-label2">Cafe & Jatetxea</label>
                    </div>
                </div>
                {!this.props.authed && 
                <div className="col-sm-4 navbar-info-div">
                    <label>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-telephone-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M2.267.98a1.636 1.636 0 0 1 2.448.152l1.681 2.162c.309.396.418.913.296 1.4l-.513 2.053a.636.636 0 0 0 .167.604L8.65 9.654a.636.636 0 0 0 .604.167l2.052-.513a1.636 1.636 0 0 1 1.401.296l2.162 1.681c.777.604.849 1.753.153 2.448l-.97.97c-.693.693-1.73.998-2.697.658a17.47 17.47 0 0 1-6.571-4.144A17.47 17.47 0 0 1 .639 4.646c-.34-.967-.035-2.004.658-2.698l.97-.969z"/>
                        </svg>  943 71 29 95
                    </label>
                </div>
                }
                {this.props.authed &&
                <div className="col-sm-4 navbar-info-div">
                    <button type="button" class="btn btn-light" onClick={this.logOutClick}> Cerrar Sesion </button>
                </div>
                }
            </div>
        );
    }
}
