import { firebaseAuth } from '../config/constants'

export function logout () {
  return firebaseAuth.signOut()
}

export function login (email, password) {
  return firebaseAuth.signInWithEmailAndPassword(email, password)
}

export function userUid(){
  return firebaseAuth.currentUser.uid
}

export function userMail(){
  return firebaseAuth.currentUser.email
}

export function userDisplayName(){
  return firebaseAuth.currentUser.displayName
}

export function userData(){
  return firebaseAuth.currentUser
}
