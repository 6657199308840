
import React from 'react'
import {ref} from '../../config/constants'
import { locStrings } from '../../language/LangResource';
import MenuCardItemDeleteButton from '../common/menuCardItemDeleteButton';
import MenuCardItemDeleteModal from '../common/menuCardItemDeleteModal';
import MenuCardItemEditButton from '../common/menuCardItemEditButton';
import MenuCardItemEditModal from '../common/menuCardItemEditModal';

export default class MenuCardItem extends React.Component {
    constructor(props){
        super(props)
        this.state = {
        }
        // this.componentDidMount = this.componentDidMount.bind(this);
    }


    componentDidMount () {
        ref.child(`menuItem/${this.props.item}`).once('value').then(result => {
            this.setState({
                title: result.val()[locStrings.getLanguage()].itemTitle,
                text: result.val()[locStrings.getLanguage()].itemText,
                price: result.val().price + " €",
            });
        })
    }

    componentWillUnmount () {
    }

    render() {
        return (
            <div className="card-body menu-item-div">
                <h5 className="card-title menu-item-title">{this.state.title}</h5>
                <p className="card-text menu-item-text">{this.state.text}</p>
                <p className="card-text menu-item-price ">{this.state.price}</p>
                {this.props.authed && 
                    <div className="text-center">
                        <MenuCardItemEditButton card={this.props.card} item={this.props.item}></MenuCardItemEditButton>
                        <MenuCardItemEditModal card={this.props.card} item={this.props.item}></MenuCardItemEditModal>
                        <MenuCardItemDeleteButton card={this.props.card} item={this.props.item}></MenuCardItemDeleteButton>
                        <MenuCardItemDeleteModal card={this.props.card} item={this.props.item}></MenuCardItemDeleteModal>
                    </div>
                }
            </div>
        )
    }
}