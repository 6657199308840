
import React from 'react'
import '../styles/site.css'
import {Link} from 'react-router-dom'
import { locStrings } from '../language/LangResource'
export default class NavBar extends React.Component {
    constructor(){
        super()
        this.state = {
        }
    }

    componentDidMount () {
    }

    componentWillUnmount () {
    }

    render() {
        return (
            <ul className="nav nav-tabs justify-content-center">
                <li className="nav-item">
                    <Link className="nav-link" to={'/inicio'} >{locStrings.inicio}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to={'/menu'} >{locStrings.menu}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to={'/vino'} >{locStrings.vino}</Link>
                </li>
                {/* <li className="nav-item">
                    <Link className="nav-link" to={'/sobreNosotros'} >{locStrings.sobreNosotros}</Link>
                </li> */}
                {/* <li className="nav-item">
                    <Link className="nav-link" to={'/contacto'} >{locStrings.contacto}</Link>
                </li> */}
            </ul>
            // <nav className="navbar navbar-expand-md navbar-light bg-light">
            //     <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            //         <span className="navbar-toggler-icon"></span>
            //     </button>
            //     <div className="collapse navbar-collapse justify-content-md-center" id="navbarSupportedContent">
            //         <ul className="navbar-nav mr-auto">
            //             <li className="nav-item">
            //                 <Link className="nav-link" to="/inicio" >Inicio</Link>
            //             </li>
            //             <li className="nav-item">
            //                 {/* <a className="nav-link" href="menu">Menu</a> */}
            //                 <Link className="nav-link" to="/menu" >Menu</Link>
            //             </li>
            //             <li className="nav-item">
            //                 {/* <a className="nav-link" href="vino">Vino</a> */}
            //                 <Link className="nav-link" to="/vino" >Vino</Link>
            //             </li>
            //             <li className="nav-item">
            //                 {/* <a className="nav-link" href="sobreNosotros">Sobre Nosotros</a> */}
            //                 <Link className="nav-link" to="/sobreNosotros" >Sobre Nosotros</Link>
            //             </li>
            //             <li className="nav-item">
            //                 {/* <a className="nav-link" href="contacto">Contacto</a> */}
            //                 <Link className="nav-link" to="/contacto" >Contacto</Link>
            //             </li>
            //         </ul>
            //     </div>
            // </nav>
        );
    }
}
