import LocalizedStrings from 'react-localization'

export const locStrings = new LocalizedStrings({
    en:{
        en: 'English',
        es: 'Spanish',
        eus: 'Vasque',
        inicio: 'Home',
        menu: 'Menu',
        vino: 'Wine',
        sobreNosotros: 'About Us',
        contacto: 'Contact'
    },
    es:{
        en: 'Ingles',
        es: 'Castellano',
        eus: 'Euskera',
        inicio: 'Inicio',
        menu: 'Menu',
        vino: 'Vino',
        sobreNosotros: 'Sobre Nosotros',
        contacto: 'Contacto'
    },
    eus:{
        en: 'Ingelesa',
        es: 'Gaztelera',
        eus: 'Euskera',
        inicio: 'Sarrera',
        menu: 'Menua',
        vino: 'Ardoak',
        sobreNosotros: 'Gutaz',
        contacto: 'Kontaktua'
    }
})